import styled from 'styled-components';

interface Props {}

export const SettingsStyled = styled.div<Props>`
  #custom-label-month {
    margin-bottom: -12px;
  }

  .editor-custom-css {
    section {
      border-radius: 8px;
      overflow: auto;
    }
  }
`;
