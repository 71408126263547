import styled from 'styled-components';

export const ThingToDoNextStyled = styled.div`
  position: relative;
  margin-top: 16px;
  .progress-container {
    display: flex;
    flex-direction: column;
    padding: 8px 16px 0px;
    margin-left: 8px;
  }
  .progress {
    display: flex;
    align-items: center;
    margin-top: 8px;
    .current-step {
      min-width: 110px;
    }
  }
  .progress-done {
    margin-top: 8px;
    width: 55px;
    display: flex;
    align-items: center;
  }
  .Polaris-LegacyCard__FirstSectionPadding {
    padding-left: 24px;
  }
  .btn-action {
    .Polaris-Button--iconOnly .Polaris-Button__Icon:last-child {
      display: none;
    }
  }
  .Polaris-LegacyCard {
    padding-bottom: 4px;
  }
  .btn-cancel {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 501;
  }
  .step {
    display: flex;
    margin-left: 28px;
    flex-direction: column;
    align-items: flex-start;
  }
  .thing-to-do-title {
    min-height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
  }
  .thing-to-do-step-close:hover {
    cursor: pointer;
    background: #f3f3f3;
    border-radius: 0.5rem;
    padding: 4px;
  }
  .thing-to-do-step-open {
    background: #f3f3f3;
    width: 98%;
    margin: 8px auto;
    padding: 4px;
    border-radius: 8px;
  }
  .thing-to-do-step-close {
    width: 98%;
    margin: 8px auto;
    padding: 4px;
    border-radius: 8px;
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px dotted gray;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: border-color 0.3s;
  }

  .circle:hover {
    border-color: #000;
    border: 2px solid #000;
  }

  .btn-dismiss {
    display: flex;
    justify-content: flex-end;
    margin: 8px;
  }
`;
