import { BlockStack } from '@shopify/polaris';
import React from 'react';

import ColorPickerCustom from '@/components/ColorPickerCustom';
import { BackgroundColor, RedirectUrl } from '@/constants/enum';
import { handleChangePreview, previewSelector } from '@/redux/slice/preview.slice';
import { TextField } from '@shopify/polaris';
import { useDispatch, useSelector } from 'react-redux';

interface ButtonSettingsProps {}

const ButtonSettings: React.FC<ButtonSettingsProps> = () => {
  const dispatch = useDispatch();
  const previewDetail = useSelector(previewSelector);

  const handleChangeTemplate = (key: keyof typeof previewDetail) => (value: string) => {
    dispatch(
      handleChangePreview({
        [key]: value,
      }),
    );
  };

  return (
    <BlockStack gap="500">
      <TextField
        autoComplete=""
        label="Submit button label"
        value={previewDetail?.submitButtonLabel || ''}
        onChange={handleChangeTemplate('submitButtonLabel')}
        maxLength={32}
        showCharacterCount
      />

      <ColorPickerCustom
        onChange={handleChangeTemplate('submitButtonBackground')}
        label="Button color"
        color={previewDetail.submitButtonBackground}
        keyColor="submitButtonBackground"
        defaultColor={BackgroundColor.BrightOrange}
      />

      <ColorPickerCustom
        onChange={handleChangeTemplate('submitButtonTextColor')}
        label="Text color"
        color={previewDetail.submitButtonTextColor}
        keyColor="submitButtonTextColor"
        defaultColor={BackgroundColor.White}
      />

      <TextField
        autoComplete=""
        label="Cancel button label"
        value={previewDetail?.cancelButtonLabel || ''}
        onChange={handleChangeTemplate('cancelButtonLabel')}
        maxLength={32}
        showCharacterCount
      />

      <ColorPickerCustom
        onChange={handleChangeTemplate('cancelButtonBackground')}
        label="Cancel button color"
        color={previewDetail.cancelButtonBackground}
        keyColor="cancelButtonBackground"
        defaultColor={BackgroundColor.LightGray}
      />

      <ColorPickerCustom
        onChange={handleChangeTemplate('cancelButtonTextColor')}
        label="Text color"
        color={previewDetail.cancelButtonTextColor}
        keyColor="cancelButtonTextColor"
        defaultColor={BackgroundColor.White}
      />

      <TextField
        autoComplete=""
        label="Cancel button redirect URL"
        value={previewDetail.redirectUrl}
        onChange={handleChangeTemplate('redirectUrl')}
        onBlur={() => {
          if (!previewDetail.redirectUrl.trim().length) handleChangeTemplate('redirectUrl')(RedirectUrl.Google);
        }}
      />

      <TextField
        autoComplete=""
        label="Validation date error message"
        value={previewDetail?.errorMessage || ''}
        onChange={handleChangeTemplate('errorMessage')}
        placeholder="Enter error message"
        maxLength={255}
        showCharacterCount
      />
    </BlockStack>
  );
};

export default ButtonSettings;
