import { config } from '@/config';
import { Embedded } from '@/constants/enum';
import { isShowSelector } from '@/redux/slice/contextualBar.slice';
import { useAppBridge } from '@shopify/app-bridge-react';
import { useSelector } from 'react-redux';

const useHandleContextualNotEmbeded = () => {
  const hide = () => {};
  return {
    hide,
  };
};

const useHandleContextual = () => {
  const shopify = useAppBridge();
  const isShowed = useSelector(isShowSelector);

  const hide = () => {
    if (isShowed) {
      shopify?.saveBar?.leaveConfirmation();
    } else {
      shopify?.saveBar?.hide('my-save-bar');
    }
  };

  return {
    hide,
  };
};

export default config.embedded === Embedded.LIVE ? useHandleContextual : useHandleContextualNotEmbeded;
