import { BackgroundColor, BackgroundType, DateOrder, InputMethod, Month, PageType, RedirectUrl } from '@/constants/enum';
import { IPreview } from '@/types/components/preview';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const preview: IPreview = {
  id: null,
  shop: '',
  appEnable: true,
  mainBackgroundType: BackgroundType.BackgroundColor,
  mainBackground: BackgroundColor.WhiteTransparent,
  popupBackground: BackgroundColor.Black,
  logo: '',
  pageTypeToShow: PageType.All,
  showInPages: [],
  minAge: '18',
  cacheTime: '10',
  headingText: 'WELCOME TO SHOP',
  headingSize: '30',
  headingColor: BackgroundColor.White,
  subHeadingText: 'You must at least 18 to visit this site',
  subHeadingSize: '16',
  subHeadingColor: BackgroundColor.White,
  submitButtonBackground: BackgroundColor.BrightOrange,
  submitButtonTextColor: BackgroundColor.White,
  submitButtonLabel: 'OK',
  cancelButtonBackground: BackgroundColor.LightGray,
  cancelButtonTextColor: BackgroundColor.White,
  cancelButtonLabel: 'CANCEL',
  inputMethod: InputMethod.NONE,
  dateTimeFormat: DateOrder.MM_DD_YY,
  customCss: null,
  monthsLabel: {
    january: Month.January,
    february: Month.February,
    march: Month.March,
    april: Month.April,
    may: Month.May,
    june: Month.June,
    july: Month.July,
    august: Month.August,
    september: Month.September,
    october: Month.October,
    november: Month.November,
    december: Month.December,
  },
  errorMessage: '',
  redirectUrl: RedirectUrl.Google,
  lastUpdate: 0,
  showWatermark: false,
};

export const initialState = {
  preview: preview,
  previewBackup: preview,
  error: {},
};

export const previewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    handleChangePreview: (state, action: PayloadAction<Partial<IPreview>>) => {
      state.preview = {
        ...state.preview,
        ...action.payload,
      };
    },
    handleCustomizeTemplate: (state, action: PayloadAction<IPreview>) => {
      state.preview = action.payload;
      state.previewBackup = action.payload;
      state.error = {};
    },
    clearAllState: (state) => {
      state.preview = preview;
      state.previewBackup = preview;
      state.error = {};
    },
  },
});

export const previewSelector = createSelector(
  (state: RootState) => state.preview,
  (state) => state.preview,
);

export const previewBackupSelector = createSelector(
  (state: RootState) => state.preview,
  (state) => state.previewBackup,
);

export const { handleChangePreview, handleCustomizeTemplate } = previewSlice.actions;
export default previewSlice;
