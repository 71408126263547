import { colors } from '@/constants/colors';
import styled from 'styled-components';

interface Props {
  bgColorPreview: string;
}

export const ColorPickerCustomerStyled = styled.div<Props>`
  .preview-color {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px dotted #999;
    background-color: ${({ bgColorPreview }) => bgColorPreview};
    cursor: pointer;
  }

  /* #collapsible-color-picker {
    .rcp-field {
      display: flex;
    }

    .rcp-body section:nth-of-type(2),
    .rcp-field-label,
    #rgb,
    #hsv {
      display: none;
    }
    .rcp-fields-floor {
      display: flex;
    }
    .rcp-body {
      background-color: ${colors.gray_background};
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
    .rcp-field-input {
      color: black;
    }
    #hex {
      border-width: 0.5px;
      max-width: 100%;
    }
  } */
`;
