import styled from 'styled-components';

export const CustomTemplateStyled = styled.div`
  .Polaris-FullscreenBar {
    text-align: center;
    .preview-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      width: 100%;
      .preview-heading-btn {
        display: flex;
      }
    }
  }

  .card-left {
    min-height: 90vh;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #eee;
  }
  .card-right {
    min-height: 90vh;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
