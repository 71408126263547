import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

// Define the initial state using that type
const initialState = {
  isShow: true,
};

export const contextualBarSlice = createSlice({
  name: 'contextualBar',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleShow: (state, action: PayloadAction<boolean>) => {
      state.isShow = action.payload;
    },
  },
});
export const isShowSelector = createSelector(
  (state: RootState) => state.contextualBar,
  (state) => state.isShow,
);

export default contextualBarSlice;
