import BoldText from '@/components/BoldText';
import Layout from '@/components/layout';
import { Placeholder } from '@/components/Placeholder';
import { link } from '@/constants/link';
import { PATH } from '@/constants/path';
import { apiCaller } from '@/redux/query';
import { isSkipApiSelector } from '@/redux/slice/auth.slice';
import { previewSelector } from '@/redux/slice/preview.slice';
import {
  ActionList,
  Badge,
  BlockStack,
  Button,
  ButtonGroup,
  Card,
  InlineGrid,
  InlineStack,
  Popover,
  Text,
  useBreakpoints,
} from '@shopify/polaris';
import { MenuHorizontalIcon } from '@shopify/polaris-icons';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Preview from '../CustomTemplate/components/Preview';
import { PopupCustomizationStyled } from './styled';

const PopupCustomization = () => {
  const { mdUp } = useBreakpoints();
  const navigate = useNavigate();
  const [popoverActive, setPopoverActive] = useState(false);
  const isSkip = useSelector(isSkipApiSelector);
  const checkEmbeddedStatus = apiCaller.useCheckAppEmbedStatusQuery(undefined, { skip: isSkip });
  const preview = useSelector(previewSelector);
  const togglePopoverActive = useCallback(() => setPopoverActive((prev) => !prev), []);

  const handlePublishToggle = useCallback(() => {
    togglePopoverActive();
    window.open(link.isOpenAppEmbedUrl, '_blank');
  }, [togglePopoverActive]);

  return (
    <Layout title="Pop-up Customization">
      <PopupCustomizationStyled>
        <Card padding="500">
          <BlockStack gap="400">
            <InlineGrid columns={{ xs: '1fr', md: '4fr 1fr' }} gap={'200'}>
              <Placeholder>
                <Preview pcScreen></Preview>
              </Placeholder>
              {mdUp && (
                <Placeholder>
                  <Preview mobileScreen></Preview>
                </Placeholder>
              )}
            </InlineGrid>
            <InlineStack align="space-between" blockAlign="center">
              <InlineStack gap="400">
                <div style={{ maxWidth: '200px', display: 'flex', alignItems: 'center', minHeight: '160px' }}>
                  <Preview miniScreen></Preview>
                </div>
                <BlockStack gap="050">
                  <div className="d-flex">
                    <BoldText>Customize age verification pop-up</BoldText>
                    <span className="ml-4">
                      <Badge tone={checkEmbeddedStatus && !checkEmbeddedStatus.data?.status ? 'critical' : 'success'}>
                        {checkEmbeddedStatus && !checkEmbeddedStatus.data?.status ? 'Unpublished' : 'Published'}
                      </Badge>
                    </span>
                  </div>
                  <Text as="p">Customize the pop-up to suit your preferences.</Text>
                  {preview.lastUpdate && (
                    <Text as="p">{`Last saved: ${dayjs(preview.lastUpdate * 1000).format('YYYY-MM-DD HH:mm:ss')}`}</Text>
                  )}
                </BlockStack>
              </InlineStack>
              <div className="group-btn">
                <ButtonGroup>
                  <Popover
                    active={popoverActive}
                    activator={<Button icon={MenuHorizontalIcon} onClick={togglePopoverActive} />}
                    autofocusTarget="first-node"
                    onClose={togglePopoverActive}
                  >
                    <ActionList
                      actionRole="menuitem"
                      items={[
                        {
                          content: checkEmbeddedStatus && checkEmbeddedStatus.data?.status ? 'Unpublish' : 'Publish',
                          onAction: handlePublishToggle,
                        },
                      ]}
                    />
                  </Popover>
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate(PATH.CUSTOM_TEMPLATE);
                    }}
                  >
                    Customize
                  </Button>
                </ButtonGroup>
              </div>
            </InlineStack>
          </BlockStack>
        </Card>
      </PopupCustomizationStyled>
    </Layout>
  );
};

export default PopupCustomization;
