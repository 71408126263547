import styled from 'styled-components';

interface Props {}
export const ProductOrCollectionListStyled = styled.div<Props>`
  .flex-column {
    flex-direction: column;
  }
  .value {
    margin-right: 8px;
  }
  .mt-4 {
    margin-top: 4px;
  }
  .btn-action {
    margin: 26px 0px 0px 8px;
  }
  .selected-tag {
    width: 100%;
    margin: 8px 0px 0px 8px;
    display: flex;
    justify-content: end;
  }
  .selected-tag-full-width {
    width: 100%;
    margin: 6px 0px 0px 0px;
    display: flex;
  }
`;
