import styled from 'styled-components';

export const OverviewStyled = styled.div`
  .overview-item {
    min-height: 100px;
    background: #F6F6F7;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .overview-title {
      margin: 1rem;
    }
    .overview-value {
      font-size: 48px;
      font-weight: bold;
    }
  }
`;
