import styled from 'styled-components';

export const DatePickerContainer = styled.div`
    .Polaris-PositionedOverlay {
        .Polaris-Popover__PopoverOverlay {
            .Polaris-Popover__PopoverOverlay--open {
                left: 560px !important
            }
        }
    }
`;

export const DatePickerStyled = styled.div`
    .Polaris-OptionList-Option {
        width: 125px;
    }
    display: flex;
    .date-picker {
    margin: 4px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    .date-picker select {
    width: 110px;
    font-size: 12px;
    padding: 5px;
    border: none;
    border-radius: 4px;
    margin: 0 5px;
    background-color: #f2f2f2;
    }
    .date-picker select:focus {
    outline: none;
    }
`;
