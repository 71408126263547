import { previewSelector } from '@/redux/slice/preview.slice';
import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Content } from './styled';

interface PlaceholderProps {
  children: React.ReactNode;
}

export const Placeholder: React.FC<PlaceholderProps> = ({ children }) => {
  const preview = useSelector(previewSelector);

  return (
    <Container>
      <Content templateCSS={preview}>
        {children}
      </Content>
    </Container>
  );
};
