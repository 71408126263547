import { BackgroundColor, BackgroundType } from '@/constants/enum';
import { isValidColor } from '@/helpers/string';
import { IPreview } from '@/types/components/preview';
import styled from 'styled-components';

interface Props {
  templateCSS: IPreview;
}

export const Container = styled.div`
  display: inherit;
  background: #d9d9d9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px 12px 0;
`;

export const Content = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  background: ${(props) =>
    props.templateCSS.mainBackgroundType === BackgroundType.BackgroundImage
      ? `url(${props.templateCSS.mainBackground})`
      : props.templateCSS.mainBackgroundType === BackgroundType.BackgroundColor
      ? isValidColor(props.templateCSS.mainBackground, BackgroundColor.White)
      : 'transparent'};
  padding-top: 2rem;
`;
