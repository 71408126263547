import { config } from '@/config';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { apiCaller } from './query';
import authSlice from './slice/auth.slice';
import bannerSlice from './slice/banner.slice';
import dashboardSlice from './slice/Dashboard/dashboard.slice';
import previewSlice from './slice/preview.slice';
import toastSlice from './slice/Toast/toast.slice';
import contextualBarSlice from './slice/contextualBar.slice';

const bannerPersistConfig = {
  key: `${config.shop}-banner`,
  storage: storageSession,
};

const authPersistConfig = {
  key: 'auth',
  storage: storageSession,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  toast: toastSlice.reducer,
  preview: previewSlice.reducer,
  dashboard: dashboardSlice.reducer,
  banner: persistReducer(bannerPersistConfig, bannerSlice.reducer),
  apiCaller: apiCaller.reducer,
  contextualBar: contextualBarSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(apiCaller.middleware);
  },
});

// setupListeners(store.dispatch)
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const persistor = persistStore(store);
export default store;
