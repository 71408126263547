import { ContextualBar } from '@/components/ContextualBar';
import Layout from '@/components/layout';
import { config } from '@/config';
import { DateOrder, Embedded, InputMethod, Month, PageType } from '@/constants/enum';
import { handleToastMutation } from '@/helpers';
import { apiCaller } from '@/redux/query';
import { handleChangePreview, initialState, previewBackupSelector, previewSelector } from '@/redux/slice/preview.slice';
import { handleToast } from '@/redux/slice/Toast/toast.slice';
import {
  BlockStack,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  Divider,
  InlineGrid,
  RadioButton,
  Select,
  Text,
  TextField,
  useBreakpoints,
} from '@shopify/polaris';
import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ProductOrCollectionList from './components/ProductOrCollectionList';
import { SettingsStyled } from './styled';
import Editor from '@monaco-editor/react';
import BoldText from '@/components/BoldText';

const optionAgeList = [...Array(100).keys()].map((i) => ({ value: String(i + 1), label: String(i + 1) }));

const choiceMethodList = [
  { label: 'No input', value: InputMethod.NONE },
  { label: 'Birthdate entry', value: InputMethod.BIRTHDATE_ENTRY },
];

// const choiceDisplayPageList = [
//   { label: 'All pages', value: PageType.All },
//   { label: 'Specific pages', value: PageType.SpecificPages },
// ];

const choiceDisplayOptionSpecific = [
  {
    label: 'Home',
    value: PageType.Home,
  },
  {
    label: 'Collection',
    value: PageType.SpecificCollections,
  },
  {
    label: 'Product',
    value: PageType.SpecificProducts,
  },
];

const optionDateOrderList = [
  { label: 'YY,MM,DD', value: DateOrder.YY_MM_DD },
  { label: 'MM,DD,YY', value: DateOrder.MM_DD_YY },
  { label: 'DD,MM,YY', value: DateOrder.DD_MM_YY },
  { label: 'YY,DD,MM', value: DateOrder.YY_DD_MM },
  { label: 'MM,YY,DD', value: DateOrder.MM_YY_DD },
  { label: 'DD,YY,MM', value: DateOrder.DD_YY_MM },
];

const Settings = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [updateTemplate, { isLoading }] = apiCaller.useUpdateTemplateMutation();
  // const fetchPagesData = apiCaller.useGetPagesQuery();
  // const pages = fetchPagesData.data?.pages.map(({ id, title }) => ({
  //   value: id,
  //   label: title,
  // }));

  const { smUp } = useBreakpoints();
  const previewDetail = useSelector(previewSelector);
  const previewDetailBackup = useSelector(previewBackupSelector);

  const monthLabelList = Object.values(Month).map((month) => ({
    label: month,
    value: previewDetail.monthsLabel[month.toLowerCase() as keyof typeof previewDetail.monthsLabel],
    key: month.toLowerCase() as keyof typeof previewDetail.monthsLabel,
  }));

  const [errorProductOrCollection, setErrorProductOrCollection] = useState('');

  const handleChangeTemplate = (key: keyof typeof previewDetail) => (value: string) => {
    dispatch(
      handleChangePreview({
        [key]: value,
      }),
    );
  };

  const handleChangeChoiceList = (key: keyof typeof previewDetail) => (selected: string[]) => {
    if (errorProductOrCollection) {
      setErrorProductOrCollection('');
    }

    dispatch(
      handleChangePreview({
        [key]: selected[0],
        showInPages: key === 'pageTypeToShow' ? [] : previewDetail.showInPages,
      }),
    );
  };

  const handleChangeMonthLabel = (value: string, keyChange: keyof typeof previewDetail.monthsLabel) => {
    dispatch(
      handleChangePreview({
        monthsLabel: {
          ...previewDetail.monthsLabel,
          [keyChange]: value,
        },
      }),
    );
  };

  const handleOnBlurMonthLabel = (keyBlur: keyof typeof previewDetail.monthsLabel) => {
    if (previewDetail.monthsLabel[keyBlur]?.trim() === '') {
      dispatch(
        handleChangePreview({
          monthsLabel: {
            ...previewDetail.monthsLabel,
            [keyBlur]: initialState.preview.monthsLabel[keyBlur],
          },
        }),
      );
    }
  };

  const arePreviewEqual = useMemo(() => {
    return isEqual(previewDetail, previewDetailBackup);
  }, [previewDetail, previewDetailBackup]);

  const handleDiscard = () => {
    if (errorProductOrCollection) setErrorProductOrCollection('');
    dispatch(handleChangePreview(previewDetailBackup));
  };

  const handleSave = async () => {
    const res = await updateTemplate({
      ...previewDetail,
      showWatermark: !!previewDetail.showWatermark,
    });
    if ('data' in res) {
      dispatch(handleToast(handleToastMutation(res)));
      return;
    }
  };

  const isDisabledSave = useMemo(() => {
    const isSpecificPageWithoutPages =
      (previewDetail.pageTypeToShow === PageType.SpecificProducts ||
        previewDetail.pageTypeToShow === PageType.SpecificCollections) &&
      !previewDetail?.showInPages?.length;

    return arePreviewEqual || isSpecificPageWithoutPages;
  }, [arePreviewEqual, previewDetail.pageTypeToShow, previewDetail?.showInPages?.length]);

  const layoutSettings =
    config.embedded !== Embedded.LIVE
      ? {
        primaryAction: {
          content: 'Save',
          onAction: handleSave,
          disabled: isDisabledSave,
          loading: isLoading,
        },
        secondaryActions: (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button disabled={arePreviewEqual} onClick={handleDiscard}>
              Discard
            </Button>
          </div>
        ),
      }
      : null;

  return (
    <Layout title="Settings" {...layoutSettings}>
      <SettingsStyled>
        {config.embedded === Embedded.LIVE ? (
          <>
            <ContextualBar
              delay={state?.prePath}
              value={previewDetail}
              loadingSave={isLoading}
              disabledDiscard={arePreviewEqual}
              disabledSave={isDisabledSave}
              onDiscard={handleDiscard}
              onSave={handleSave}
            />
          </>
        ) : null}
        <BlockStack gap={{ xs: '800', sm: '400' }}>
          <InlineGrid columns={{ xs: '1fr', md: '1fr 4fr' }} gap="400">
            <BlockStack gap="400">
              <Text as="h3" variant="headingMd">
                General
              </Text>
              <Text as="p" variant="bodyMd" tone="subdued">
                Configure the minimum age verification and its method.
              </Text>
            </BlockStack>

            <Card padding="500">
              <BlockStack gap="400">
                <Select
                  value={previewDetail.minAge}
                  label={<BoldText>Verify age</BoldText>}
                  options={optionAgeList}
                  onChange={handleChangeTemplate('minAge')}
                />

                <ChoiceList
                  choices={choiceMethodList}
                  selected={[String(previewDetail.inputMethod)]}
                  title={<BoldText>Method</BoldText>}
                  onChange={handleChangeChoiceList('inputMethod')}
                />
              </BlockStack>
            </Card>
          </InlineGrid>
          {smUp ? <Divider /> : null}

          <InlineGrid columns={{ xs: '1fr', md: '1fr 4fr' }} gap="400">
            <BlockStack gap="400">
              <Text as="h3" variant="headingMd">
                Advanced
              </Text>
              <Text as="p" variant="bodyMd" tone="subdued">
                Configure conditions and labels to best match your store.
              </Text>
            </BlockStack>

            <Card padding="500">
              <BlockStack gap="400">
                <InlineGrid columns={2} gap="400">
                  <TextField
                    autoComplete=""
                    label={<BoldText>Remember visitor for</BoldText>}
                    value={previewDetail.cacheTime}
                    type="number"
                    onChange={handleChangeTemplate('cacheTime')}
                    onBlur={() => handleChangeTemplate('cacheTime')(Number(previewDetail.cacheTime).toFixed())}
                  />
                </InlineGrid>

                <InlineGrid columns={2} gap="400">
                  <BlockStack gap="050">
                    <BoldText>Display page(s)</BoldText>
                    <BlockStack>
                      <RadioButton
                        label="All pages"
                        checked={previewDetail.pageTypeToShow === PageType.All}
                        id={PageType.All}
                        onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.All])}
                      />
                      <RadioButton
                        label="Specific pages"
                        id={PageType.SpecificPages}
                        checked={previewDetail.pageTypeToShow !== PageType.All}
                        onChange={() => handleChangeChoiceList('pageTypeToShow')([PageType.Home])}
                      />
                    </BlockStack>
                  </BlockStack>
                </InlineGrid>

                <Collapsible
                  open={previewDetail.pageTypeToShow !== PageType.All}
                  id="select-specific-page"
                  expandOnPrint
                  transition={{ duration: '300ms', timingFunction: 'ease-in-out' }}
                >
                  <InlineGrid columns={2} gap="400">
                    <Select
                      value={previewDetail.pageTypeToShow}
                      options={choiceDisplayOptionSpecific}
                      label={<BoldText>Select specific page</BoldText>}
                      onChange={(value) => handleChangeChoiceList('pageTypeToShow')([value])}
                    />
                    <ProductOrCollectionList
                      errorProductOrCollection={errorProductOrCollection}
                      setErrorProductOrCollection={setErrorProductOrCollection}
                    />
                  </InlineGrid>
                </Collapsible>

                <InlineGrid columns={2} gap="400">
                  <Select
                    options={optionDateOrderList}
                    label={<BoldText>Date order</BoldText>}
                    value={previewDetail.dateTimeFormat}
                    onChange={handleChangeTemplate('dateTimeFormat')}
                  />
                </InlineGrid>

                <Text variant="headingMd" id="custom-label-month" as="span">
                  Custom label for month
                </Text>

                <InlineGrid columns={2} gap="400">
                  {monthLabelList.map((month, idx) => (
                    <TextField
                      maxLength={32}
                      onChange={(value) => handleChangeMonthLabel(value, month.key)}
                      key={`${month.label}${idx}`}
                      label={month.label}
                      autoComplete=""
                      value={month?.value || ''}
                      onBlur={() => handleOnBlurMonthLabel(month.key)}
                    />
                  ))}
                </InlineGrid>

                <div className="editor-custom-css">
                  <Text variant="headingMd" as="span">
                    Custom CSS
                  </Text>
                  <Editor
                    height="400px"
                    onChange={(value) => dispatch(handleChangePreview({ customCss: value }))}
                    defaultLanguage="css"
                    theme="vs-dark"
                    defaultValue={previewDetail.customCss || ''}
                    options={{
                      automaticLayout: true,
                    }}
                  />
                </div>
              </BlockStack>
            </Card>
          </InlineGrid>
          {smUp ? <Divider /> : null}
        </BlockStack>
      </SettingsStyled>
    </Layout>
  );
};

export default Settings;
