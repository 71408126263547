import { BackgroundColor } from '@/constants/enum';
import colorString from 'color-string';

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function uniq(a: Array<any>) {
  return Array.from(new Set(a));
}

export const stringify = (input: any) => {
  return JSON.stringify(input);
};

export const isValidColor = (color: string, defaultColor: BackgroundColor) => {
  if (color) {
    return colorString?.get(color) !== null ? color : defaultColor;
  } else {
    return defaultColor;
  }
};
