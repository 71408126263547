import { apiCaller } from '@/redux/query';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

function useGetProducts() {
  const [productOptions, setProductOptions] = useState<{ value: string; label: string }[]>([]);
  const [getProducts, getProductsStatus] = apiCaller.useLazyGetProductsQuery();

  const handleGetProducts = useCallback(
    async (inputSearch: string) => {
      try {
        const res = await getProducts({ search: inputSearch });

        if (res.data?.statusCode === 200) {
          const options = res.data.listProducts.map((item) => ({
            value: item.id,
            label: item.title,
          }));
          setProductOptions(options);
        }
      } catch (error) {
        console.log('🚀 - error:', error);
      }
    },
    [getProducts],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetProducts = useCallback(
    debounce((inputSearch: string) => {
      handleGetProducts(inputSearch);
    }, 200),
    [handleGetProducts],
  );

  useEffect(() => {
    handleGetProducts('');
  }, [handleGetProducts]);

  return { productOptions, debouncedGetProducts, getProductsStatus };
}

export default useGetProducts;
