import { useCallback, useEffect, useState } from 'react';
import { apiCaller } from '@/redux/query';
import { debounce } from 'lodash';

function useGetCollections() {
  const [collectionOptions, setCollectionOptions] = useState<{ value: string; label: string }[]>([]);
  const [getCollections, getCollectionsStatus] = apiCaller.useLazyGetCollectionsQuery();

  const handleGetCollections = useCallback(
    async (inputSearch: string) => {
      try {
        const res = await getCollections({ search: inputSearch });

        if (res.data?.statusCode === 200) {
          const options = res.data.listCollections.map((item) => ({
            value: item.id,
            label: item.title,
          }));
          setCollectionOptions(options);
        }
      } catch (error) {
        console.log('🚀 - error:', error);
      }
    },
    [getCollections],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetCollections = useCallback(
    debounce((inputSearch: string) => {
      handleGetCollections(inputSearch);
    }, 300),
    [handleGetCollections],
  );

  useEffect(() => {
    handleGetCollections('');
  }, [handleGetCollections]);

  return { collectionOptions, debouncedGetCollections, getCollectionsStatus };
}

export default useGetCollections;
