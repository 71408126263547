import { BackgroundType } from '@/constants/enum';
import { IPreview } from '@/types/components/preview';
import styled from 'styled-components';

interface Props {
  templateCSS: IPreview;
  mobileScreen?: boolean;
  pcScreen?: boolean;
  miniScreen?: boolean;
}

export const PreviewStyled = styled.div<Props>`
  width: 100%;
  height: 100%;
  .preview {
    height: ${(props) => (props.miniScreen && props.templateCSS.logo ? '530px' : '100%')};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 0 auto;
    background: ${(props) =>
      props.mobileScreen || props.pcScreen
        ? 'none'
        : props.templateCSS.mainBackgroundType === BackgroundType.BackgroundImage
        ? `url(${props.templateCSS.mainBackground})`
        : props.templateCSS.mainBackgroundType === BackgroundType.BackgroundColor
        ? props.templateCSS.mainBackground || ''
        : 'transparent'};
    background-repeat: no-repeat;
    background-size: cover;

    .preview-container {
      width: ${(props) => (props.miniScreen || props.pcScreen || props.mobileScreen ? '100%' : '80%')};
      background: ${(props) => props?.templateCSS?.popupBackground || ''};
      color: #fff;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      .preview-logo {
        padding: 3rem 0rem 0rem;
        img {
          max-width: 150px;
          max-height: 150px;
        }
      }

      .preview-title {
        padding: 3rem 0.5rem 0.5rem;
        text-align: center;
        word-break: break-word;
        font-size: ${(props) =>
          props.mobileScreen ? +props?.templateCSS?.headingSize / 1.2 + 'px' : props?.templateCSS?.headingSize + 'px'};
        color: ${(props) => props?.templateCSS?.headingColor || ''};
        font-weight: bold;
        line-height: 2rem;
      }

      .preview-content {
        padding: 1rem;
        text-align: center;
        word-break: break-word;
        font-size: ${(props) =>
          props.mobileScreen ? +props.templateCSS.subHeadingSize / 1.2 + 'px' : props.templateCSS.subHeadingSize + 'px'};
        color: ${(props) => props?.templateCSS?.subHeadingColor || ''};
        font-weight: 600;
        line-height: 2rem;
      }

      .preview-date {
        display: flex;
        justify-content: space-between;
        select {
          border-radius: 4px;
          margin: 0.5rem 0.1rem 0;
          min-height: 25px;
          max-width: 100px;
        }
      }

      .preview-group-btn {
        margin: 2rem 3rem 1rem;
        .submit-button,
        .cancel-button {
          width: 170px;
          height: 40px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            opacity: 0.8;
            transition: all 0.2s ease;
          }
        }
        .submit-button {
          background-color: ${(props) => props?.templateCSS?.submitButtonBackground || ''};
          color: ${(props) => props?.templateCSS?.submitButtonTextColor || ''};
        }

        .cancel-button {
          background-color: ${(props) => props?.templateCSS?.cancelButtonBackground || ''};
          color: ${(props) => props?.templateCSS?.cancelButtonTextColor || ''};
        }
      }

      .watermark-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2rem;
        img {
          margin: 0 4px;
        }
        .watermark-link {
          color: rgba(0, 91, 211, 1);
          text-decoration: underline;
          cursor: pointer;
        }
      }

      @media screen and (min-width: 709px) and (max-width: 996px) {
        .preview-group-btn {
          button {
            width: 122px;
            height: 35px;
          }
        }
      }
    }
  }
`;
