import contextualBarSlice, { isShowSelector } from '@/redux/slice/contextualBar.slice';
import { Modal, SaveBar, useAppBridge } from '@shopify/app-bridge-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
interface IProps {
  onSave: () => void;
  onDiscard: () => void;
  disabledSave: boolean;
  disabledDiscard: boolean;
  loadingSave: boolean;
  value: any;
  delay?: boolean;
}
export function ContextualBar({ onSave, onDiscard, disabledDiscard, disabledSave, value, loadingSave, delay }: IProps) {
  const isShowed = useSelector(isShowSelector);
  const dispatch = useDispatch();
  const [isTurnOffDelay, setIsTurnOffDelay] = useState(false);
  const shopify = useAppBridge();
  const handleSave = () => {
    onSave();
    shopify.saveBar.hide('my-save-bar');
    dispatch(contextualBarSlice.actions.handleShow(false));
  };

  const handleDiscard = () => {
    onDiscard();
    shopify.saveBar.hide('my-save-bar');
    dispatch(contextualBarSlice.actions.handleShow(false));
  };

  useEffect(() => {
    if (!isTurnOffDelay && delay) {
      setIsTurnOffDelay(true);
      const timer = setTimeout(() => {
        if (!disabledSave || !disabledDiscard) {
          if (!isShowed) {
            shopify.saveBar.show('my-save-bar');
            dispatch(contextualBarSlice.actions.handleShow(true));
          }
        } else {
          if (isShowed) {
            shopify.saveBar.hide('my-save-bar');
            dispatch(contextualBarSlice.actions.handleShow(false));
          }
        }
      }, 200);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTurnOffDelay || !delay) {
      if (!disabledSave || !disabledDiscard) {
        if (!isShowed) {
          shopify.saveBar.show('my-save-bar');
          dispatch(contextualBarSlice.actions.handleShow(true));
        }
      } else {
        if (isShowed) {
          shopify.saveBar.hide('my-save-bar');
          dispatch(contextualBarSlice.actions.handleShow(false));
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledDiscard, disabledSave, JSON.stringify(value)]);

  return (
    <>
      <SaveBar id="my-save-bar">
        <button variant="primary" loading={loadingSave} disabled={disabledSave} onClick={handleSave}></button>
        <button disabled={disabledDiscard} onClick={handleDiscard}></button>
      </SaveBar>
      <Modal></Modal>
    </>
  );
}
